.textloading{
  padding: 40px;
  height:300px;
  display: flex;
  align-items:center;
  justify-content:center;
}
.title-orders{
  margin-top:30px;
}
.list-orders{
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 2px;
}
.vermelho {
  color:#f00;
}

