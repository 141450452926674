.font-face-bs {
  font-family: "Bison";
}
.font-rbt{
  font-family: 'Roboto Condensed', sans-serif;
}

body{
  background-color: #fff
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  width: 100%;
  margin-bottom: 15px;
  padding: 12px 16px;
  border-radius: 4px;
  border: 2px solid #ddd;
  font-size: 15px;
  color: #444;
  transition: border-color 0.2s;
}

input:focus {
  border-color: #111;
  border: 3px solid #ddd;
}