.font-face-bs {
  font-family: "Bison";
 }

.card__wrapper {
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card {
  padding-top: 10px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #222;
  min-width: 300px;
}

.card:hover{
  border: 1px solid #f00;
}

.card h2{
  border-bottom: 1px solid #707070;
  margin: 0 10px;
  user-select: none;
  max-width: 250px;
  min-height: 80px;
  text-align: left;
}

.card h5{
  color:#f00;
}

.haeder__card{
  display: flex;
  width: 90%;
  justify-content: space-between;
  user-select: none;
}

.haeder__card .redes img{
  cursor: pointer;
}

.haeder__card .redes img{
  color:#f00;
}

.category{
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.imgproduct img{
  margin-top:20px;
  margin-bottom:20px;
  cursor: pointer;
}

.preco_sugerido{
  display: flex;
  width: 90%;
  margin-top: 20px;
  justify-content: space-between;
}

.footer__card{
  display: flex;
  width: 90%;
  justify-content: space-between;
  user-select: none;
  margin-bottom:10px;
}

.footer__card p{
  margin-bottom:0px;
}

.colorsize{
  display: flex;
  width: 90%;
  justify-content: space-between;
  user-select: none;
  margin-top: 20px;
}


.footer__card .font-rbt{
  font-family: 'Roboto Condensed', sans-serif;
  padding-top:2px;
}

.button__card{
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.btn-gobike {
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #f00;
  color:#fff;
  border-width: 1px;
  border-color: #f00;
}
.btn-gobike:hover {
  background-color: rgb(218, 9, 9);
  color:#fff;
}
