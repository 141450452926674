.breadcrumbs {
  user-select: none;
  margin-top:40px;
  margin-left: 20px;
  font-size:18px;
  border-bottom: 1px solid #707070;
}

.breadcrumbs span{
  cursor: pointer;
}

@media(max-width: 414px) {
  .breadcrumbs {
    margin-top:20px;
    margin-left: 0px;
  }
}
