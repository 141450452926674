
.btn-slide{
  background-color:#ff2841;
  border-width: 1px;
  border-color: #ff2841;
  font-weight:bold;
  text-align:left;
  float: left;
  border-radius: 10px;
  padding:4px 15px 5px 5px;
  color: #fff;
  margin-right: 30px;
  user-select: none;
}
.btn-slide:hover{
  text-shadow: 2px 2px 2px rgb(43, 43, 43);
}
.btn-slide i{
  font-size:20px;
  padding:10px;
  vertical-align: middle;
}

.btn-slide-white{
  background-color:transparent !important;
  border-width: 2px;
  border-color: #fff;
  font-weight:bold;
  text-align:left;
  float: left;
  border-radius: 10px;
  padding:4px 17px 3px 5px;
  color: #fff;
  margin-right: 30px;
  user-select: none;
}
.btn-slide-white:hover{
text-shadow: 2px 2px 2px rgb(43, 43, 43);
}
.btn-slide-white i{
  font-size:20px;
  padding:10px;
  vertical-align: middle;
}

.tituloslide{
  text-align:left;
  line-height:0.9;
  text-shadow: 2px 2px 2px rgb(43, 43, 43);
  user-select: none;
}
.subtituloslide{
  text-align:left;
  color:#ff2841;
  text-shadow: 2px 2px 2px rgb(43, 43, 43);
  user-select: none;
}

@media(min-width: 769px) {
  
  .tituloslide{
    font-size:4em;
  }
  .subtituloslide{
    font-size:2em;
  }
}

@media(min-width: 1024px) {
  
  .tituloslide{
    font-size:5em;
  }
  .subtituloslide{
    font-size:2em;
  }
}


@media(min-width: 1440px) {

  .tituloslide{
    font-size:7em;
  }
  .subtituloslide{
    font-size:2em;
  }

  .btn-slide{
    font-size:1rem;
    margin-bottom: 50px;
  }
  .btn-slide-white{
    font-size:1rem;
  }
}


@media(min-width: 2000px) {

  .tituloslide{
    font-size:10em;
  }
  .subtituloslide{
    font-size:3em;
  }

  .btn-slide{
    font-size:2rem;
    margin-bottom: 100px;
  }
  .btn-slide-white{
    font-size:2rem;
  }
}


@media(max-width: 415px) {
  .slide-home{
    display:none;
  } 
}





















/* https://html-css-js.com/css/generator/text-shadow/ */