.links {
  display: flex;
  justify-content:space-around;
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
}

.linkactive{
  color:#f00;
}


@media(min-width: 1143px) {
  .links{
    display: none
  }
}