.cordotitulocontato h3{
  padding-left: 20px;
  color:#f00;
}

.cordotitulocontato p{
  padding-left: 20px;
  color:#000;
}

.subdotitulocontato{
  padding-left: 20px;
}

.form{
  padding-left:20px;
}

.animacao_contato{
  display:flex;
  align-items:center;
  justify-content:center;
}

@media(max-width: 414px) {

  .cordotitulocontato h3{
    padding-left: 0px;
    color:#f00;
  }  
  .cordotitulocontato p{
    padding-left: 0px;
    color:#000;
  }  
  .subdotitulocontato{
    padding-left: 0px;
    font-size:10px;
  }  
  .form{
    padding-left:0px;
  }
}