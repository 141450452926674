.menu-vercelli {
  margin: 0 auto;
  text-align: center;
  margin-top:5px;
  display: flex;
  justify-content: flex-end;
}

.menu-vercelli p{
  float: left;
  padding: 0px 15px;
  margin: 0px 15px;
  font-size: 16px;
  cursor: pointer;
}
.menu-vercelli p:hover, i:hover{
  color:#E4001A;
}

.menu-vercelli img{
  padding-right: 5px;
  width:20px;
}


@media(max-width: 1144px) {
  .menu-vercelli{
    display: none
  }
}