@keyframes fadeIn {
  0% { opacity: 0.8; }
  100% { opacity: 1; }
}

.boxcoresdisponiveis{
  display:flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.quadradodacor{
  display: flex;
  width:20px;
  height:20px;
  margin: 5px 20px;
  border-radius: 10%;
  cursor: pointer;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
}

.quadradodacorselect{
  display: flex;
  width:20px;
  height:20px;
  margin: 5px 20px;
  border-radius: 10%;
  border: 1px solid #000;
  cursor: pointer;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
}

.linhazinhaentrecoresetamanhos{
  color:#222;
  text-align: center;
  width: 100%;
}

.linhazinhaentrecoresetamanhos hr{
  width: 70%;
  margin: 5px auto;
}

.tamanhosdisponiveis{
  display:flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.quadradodotamanho{
  display: flex;
  align-items: center;
  justify-content: center;
  width:40px;
  height:40px;
  margin: 5px 20px;
  border-radius: 10%;
  cursor: pointer;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
}
.quadradodotamanhoselect{
  display: flex;
  align-items: center;
  justify-content: center;
  width:40px;
  height:40px;
  margin: 5px 20px;
  border-radius: 10%;
  border: 1px solid #008000;
  color:#008000;
  cursor: pointer;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
}

.semestoque{
  border: 1px solid #f00;
  position:relative;
  color: #f00
}

.semestoque:before{
  content:"";
  position:absolute;
  border-top:1px solid red;
  width:40px;
  transform: rotate(-45deg);
  transform-origin: 50% 0%;
}


.codigodabike{
  margin-top: 10px;
  color:#f00;
  display: flex;
  align-items:flex-end;
  justify-content: flex-end;
  font-size: 1rem;
}

.coresdabike{
  display: flex;
  margin-bottom: 10px;
}

.codigodabike span{color:#333}

.slide{
  text-align: center;
  max-width: 100%;
  margin-bottom: 30px;
  margin-top: 20px;
}

.slide img{
  animation: fadeIn 1s;
}

/* .insideproduct img{
  border-radius:0 0 100px 100px;
} */

.links-slide{
  display: flex;
  justify-content: flex-end;
  margin-top:50px;
  vertical-align: middle;
}

.bikesRelacionadas{
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 20px;
}

.select{
  margin: 5px;
}

.blur {
  filter: blur(2px);
  animation: fadeIn 2s;
}

.CardBike{
  max-width:100px;
  cursor: pointer;
  margin: 3px;
  border-radius: 5px;
  background-color:#fff;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
}

.CardBike img{
  max-width:100%;
  animation: fadeIn 1s;
}

.text_size_bike{
  font-size: 18px;
}

.circulo{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #222;
  margin: 2px;
}

.palete_color{
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.quad {
  width: 20px;
  height: 20px;
}

.titleBikeRelacionada{
  padding:50px
}


.titulobikedesktop{
  display: flex;
  margin-bottom: 0px;
  border-bottom: 1px solid #707070;
}

.redesbike{
  display: flex;
  justify-content: flex-end;
  align-items:center;
  border-bottom: 1px solid #707070;
}

.icon-rede {
  color: #222;
  margin-left: 10px;
}
.icon-rede:hover {
  color: #f00;
  margin-left: 10px;
}

.pricestock{
  display: flex;
  align-items: center;
}

.price{
  display: flex;
  font-size:41px;
  color:#f00;
}

.atributos_bike{
  margin-bottom: 20px;
  text-transform: capitalize;
  background-color: #fff;
}

.atributos_bike p{
  font-size:14px;
  border-top:1px solid #ccc;
  margin-bottom: 0px;
}

.atributobike{
  width:65px;
  height:50px;
  margin-right:12px;
}

.description{
  margin-top:30px;
  background-color:#fff;
  border-radius: 5px;
}

.buttonsbike{
  padding: 10px;
  font-family: "Bison";
}

.btn-comprar {
  margin-right: 10px;
  background-color: #f00;
}
.btn-comprar:hover {
  margin-right: 10px;
  background-color: rgb(183, 6, 6);
}

.updtatecontent{
  margin-top: 40px;
}

.form-vercelli{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap
}

.form-control:focus {
  border-color: #008000;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 1);
} 

.msgsucess{
  text-align: center;
}

.font-rbt{
  font-family: 'Roboto Condensed', sans-serif;
}



@media(min-width: 415px) {
  .titulobikemobile{
    display:none;
  }
}


@media(max-width: 414px) {
  .titulobikedesktop{
    display:none;
  }
  .titulobikemobile{
    font-size:30px;
    margin-top: 20px;
  }
  .titulobikemobile h1{
    font-size:32px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }

  .codigodabike{
    justify-content:flex-start;
    margin-top: 0px;
  }

  .links-slide {
    display:none;
  }

  .redesbike{
    margin-top:20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 10px;
  }

  .atributobike {
    width: 50px;
    height: 40px;
    margin-right: 12px;
  }

  .bikesRelacionadas {
    margin-left: 0px;
  }
}

