.headertitle{
  display:flex;
  text-align:center;
  border-bottom:1px solid #ccc;
}
.title{
  margin-bottom:0px;
}
.subtitle{
  color:#f00;
}