.product__wrapper {
  display: flex;
  flex-direction: column;
}

.card__container {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.font-face-bs {
  font-family: "Bison";
 }

.categories {
  display: flex;
  justify-content:space-around;
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
}

.catactive{
  color:#f00;
}

.categories p:hover{
  color:#f00;
  cursor: pointer;
}

.search {
  margin-top:10px;
  max-width: 100%;
  display: flex;
  justify-content: center;
}


.textloading{
  padding: 40px;
  height:300px;
  display: flex;
  align-items:center;
  justify-content:center;
}

.not__found{
  text-align: center;
  padding:30px;
  margin-top:50px;
}
.not__found p:hover{
  color: #f00;
}


@media(max-width: 414px) {
  .categories p{
    font-size:16px;
  }
}
