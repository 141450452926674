.titleempresa h3{
  padding-left: 20px;
  color:#f00;
}
.titleempresa p{
  color:#000;
  padding-left: 20px;
}

.animacao_bike{
  display:flex;
  align-items:center;
  justify-content:center;
}

@media(max-width: 414px) {
  .titleempresa h3{
    padding-left: 0px;
    color:#f00;
  }

  .titleempresa p {
    color: #000;
    padding-left: 0px;
}
}
