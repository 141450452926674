.header-vercelli{
  vertical-align: middle;
  padding-top:30px;
  padding-bottom:20px;
  border-bottom: 1px solid #D3D2D2;
}
.logoemenu{
  display: flex;
}

.logo-vercelli{
  max-width:400px;
  margin-right: 30px;
}

.iconmenu {
  vertical-align: middle;
  padding-top:5px;
  padding-bottom:10px;
  cursor: pointer;
}


@media(max-width: 414px) {
  .logo-vercelli{
    max-width:300px;
    margin-right: 30px;
  }
}
